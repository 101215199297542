<template>
  <div class="content03">
    <p class="message">この内容でお問い合わせしますか？</p>
    <div class="wrap">
      <form action="">
        <dl>
          <dt>ニックネーム</dt>
          <dd>{{ contact.nickname }}</dd>
        </dl>
        <dl>
          <dt>メールアドレス</dt>
          <dd>{{ contact.email }}</dd>
        </dl>
        <!-- <dl>
          <dt>お問い合わせ種別</dt>
          <dd>{{ contact.type }}</dd>
        </dl> -->
        <dl>
          <dt>お問い合わせ内容</dt>
          <dd>
            {{ contact.body }}
          </dd>
        </dl>
        <p class="submit">
          <input
            type="submit"
            value="送  信"
            v-on:click.prevent="submitContact()"
          />
        </p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "InquiryConfirm",
  computed: {
    contact() {
      return this.$store.getters.getContact;
    },
  },
  methods: {
    submitContact() {
      this.$store.dispatch("sendInquiry", this.contact).then((response) => {
        if (response.data.result) {
          this.$router.push({ name: "InquiryThanks" });
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_form_page.css" scoped></style>
<style lang="css" src="@/assets/css/style_form_parts.css" scoped></style>
